import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import format from 'date-fns/format';
import subBusinessDays from 'date-fns/subBusinessDays';
import isWeekend from 'date-fns/isWeekend';
import addMinutes from 'date-fns/addMinutes';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../scripts/tv.js';
import firebase, { hotlistsCollection, hotlistsRef } from '../firebase/firebase';
import IHotlistPick from '../interfaces/IHotlistPick';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';
import Setup from './Setup';

class UploadHotlist extends React.Component<any> {
  initialDate = () => {
    let date = new Date();
    date.setHours(12, 0, 0, 0);
    return isWeekend(date) ? subBusinessDays(date, 1) : date;
  };

  state = {
    date: this.initialDate(),
    exchange: "",
    stock: "",
    setup: "",
    chartImage: File.prototype,
    exchangeValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    stockValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    setupValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    chartImageValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    },
    hotlistPicks: [],
    uploadDisabled: false,
    showModal: false
  };

  chartWidgetOptions = {
    "autoSize": true,
    "symbol": this.state.exchange + ":" + this.state.stock,
    "interval": "D",
    "timezone": "Etc/UTC",
    "theme": "light",
    "style": "1",
    "locale": "en",
    "enable_publishing": false,
    "allow_symbol_change": true,
    "container_id": "chartWidgetContainer"
  };
  
  modalOptions: IModalOptions = {
    title: "ERROR",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false, uploadDisabled: false });
    }
  };

  loadHotlistPicks = (date: Date) => {
    this.setState({ hotlistPicks: [] });
    date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
    const timestamp = firebase.firestore.Timestamp.fromDate(date);
    hotlistsCollection.where("date", "==", timestamp).get().then((hotlistPicksSnapshot) => {
      let hotlistPicks: IHotlistPick[] = [];
      hotlistPicksSnapshot.forEach(doc => {
        let docData = doc.data();
        let docDataTimestamp = docData.date as firebase.firestore.Timestamp;
        docData.date = docDataTimestamp.toDate();
        hotlistPicks.push(docData as IHotlistPick);
      });
      this.setState({ hotlistPicks: hotlistPicks });
    }).catch(() => {
      this.modalOptions.body = "The hotlist picks could not be retrieved.";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  setDate = (date: Date) => {
    this.setState({ date: date });
    this.loadHotlistPicks(date);
    this.clearHotlistPickForm();
  };
  
  handleInputChange = (event: any) => {
    let value = event.target.value;
    let valid = event.target.checkValidity();
    let validationMessage = event.target.validationMessage;
    if (event.target.name === "exchange") {
      value = value.toUpperCase();
    }
    else if (event.target.name === "stock") {
      value = value.toUpperCase();
      const hotlistPickForStock = this.state.hotlistPicks.find((hotlistPick: any) => hotlistPick.stock === value);
      if (valid && hotlistPickForStock) {
        valid = false;
        validationMessage = "There is already a hotlist pick for this stock.";
      }
    }
    else if (event.target.files) {
      value = event.target.files.length > 0 ? event.target.files[0] : File;
      if (event.target.files.length > 0) {
        const validFileTypes = event.target.accept.split(', ');
        if (!validFileTypes.includes(event.target.files[0].type)) {
          valid = false;
          validationMessage = "Please select a valid image.";
        }
      }
    }
    this.setState({
      [event.target.name]: value,
      [event.target.name + "Validation"]: {
        dirty: true,
        valid: valid,
        validationMessage: validationMessage
      }
    });
  };

  testSymbol = () => {
    this.chartWidgetOptions.symbol = `${this.state.exchange}:${this.state.stock}`;
    new (window as any).TradingView.widget(this.chartWidgetOptions);
    let chartWidgetContainer = document.getElementById("chartWidgetContainer") as HTMLDivElement;
    chartWidgetContainer.classList.add("mb-2");
  };

  canUpload = () => {
    return (
      !this.state.uploadDisabled &&
      this.state.exchangeValidation.dirty && this.state.exchangeValidation.valid && 
      this.state.stockValidation.dirty && this.state.stockValidation.valid && 
      this.state.setupValidation.dirty && this.state.setupValidation.valid && 
      this.state.chartImageValidation.dirty && this.state.chartImageValidation.valid
    );
  };

  clearHotlistPickForm = () => {
    this.setState({
      exchange: "",
      stock: "",
      setup: "",
      chartImage: File.prototype,
      exchangeValidation: {
        dirty: false,
        valid: false,
        validationMessage: ""
      },
      stockValidation: {
        dirty: false,
        valid: false,
        validationMessage: ""
      },
      setupValidation: {
        dirty: false,
        valid: false,
        validationMessage: ""
      },
      chartImageValidation: {
        dirty: false,
        valid: false,
        validationMessage: ""
      }
    });

    let chartWidgetContainer = document.getElementById("chartWidgetContainer") as HTMLDivElement;
    if (chartWidgetContainer.firstChild) {
      chartWidgetContainer.removeChild(chartWidgetContainer.firstChild!);
      chartWidgetContainer.classList.remove("mb-2");
    }

    let hotlistPickForm = document.getElementById("hotlistPickForm") as HTMLFormElement;
    hotlistPickForm.reset();
  };

  uploadHotlistPick = () => {
    this.setState({ uploadDisabled: true });
    const date = new Date(Date.UTC(this.state.date.getUTCFullYear(), this.state.date.getUTCMonth(), this.state.date.getUTCDate(), 0, 0, 0, 0));
    const formattedHotlistDate = format(addMinutes(date, date.getTimezoneOffset()), "yyyyMMdd");
    const extension = this.state.chartImage.name.split('.').pop();
    const chartImageStoragePath = `${formattedHotlistDate}/${this.state.stock}.${extension}`;
    hotlistsRef.child(chartImageStoragePath).put(this.state.chartImage).then((chartImageSnapshot) => {
      chartImageSnapshot.ref.getDownloadURL().then((chartImageURL: string) => {
        const hotlistPick = {
          date: date,
          exchange: this.state.exchange,
          stock: this.state.stock,
          setup: this.state.setup,
          chartImageURL: chartImageURL
        };
        const hotlistPickDocName = `${formattedHotlistDate}-${this.state.stock}`;
        hotlistsCollection.doc(hotlistPickDocName).set(hotlistPick).then(() => {
          this.clearHotlistPickForm();
          this.loadHotlistPicks(this.state.date);
          this.setState({ uploadDisabled: false });
        }).catch(() => {
          this.modalOptions.body = "The hotlist pick could not be uploaded.";
          this.modalOptions.show = true;
          this.setState({ showModal: true });
        });
      }).catch(() => {
        this.modalOptions.body = "The chart image was stored but its link could not be retrieved for the hotlist pick upload.";
        this.modalOptions.show = true;
        this.setState({ showModal: true });
      });
    }).catch(() => {
      this.modalOptions.body = "The chart image could not be stored so the hotlist pick was not uploaded.";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  deleteHotlistPick = (event: any, index: number) => {
    event.stopPropagation();
    const hotlistPick = this.state.hotlistPicks[index] as IHotlistPick;
    const date = new Date(Date.UTC(hotlistPick.date.getUTCFullYear(), hotlistPick.date.getUTCMonth(), hotlistPick.date.getUTCDate(), 0, 0, 0, 0));
    const formattedHotlistDate = format(addMinutes(date, date.getTimezoneOffset()), "yyyyMMdd");
    const hotlistPickDocName = `${formattedHotlistDate}-${hotlistPick.stock}`;
    hotlistsCollection.doc(hotlistPickDocName).delete().then(() => {
      hotlistsRef.storage.refFromURL(hotlistPick.chartImageURL).delete().then(() => {
        this.loadHotlistPicks(hotlistPick.date);
      }).catch(() => {
        this.modalOptions.body = "The hotlist pick was deleted but its chart image could not be deleted.";
        this.modalOptions.show = true;
        this.setState({ showModal: true });
      });
    }).catch(() => {
      this.modalOptions.body = "The hotlist pick could not be deleted.";
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  showModal = (body: string) => {
    this.modalOptions.body = body;
    this.modalOptions.show = true;
    this.setState({ showModal: true });
  }

  componentDidMount = () => {
    let dayPickerInput = document.getElementsByClassName("DayPickerInput")[0].getElementsByTagName("input")[0];
    dayPickerInput.readOnly = true;
    this.loadHotlistPicks(this.state.date);
  };

  render() {
    const hotlistPicks = this.state.hotlistPicks.map((hotlistPick: IHotlistPick, index) => {
      const date = format(hotlistPick.date, "MMM do");
      const symbol = `${hotlistPick.exchange}:${hotlistPick.stock}`;
      return (
        <Card key={index} className="mt-2 mb-0">
          <Accordion.Toggle as={Button} variant="learn2stock d-flex justify-content-between" eventKey={index.toString()}>
            {symbol}
            <FontAwesomeIcon className="iconButton ml-1" icon={faTrashAlt} size="lg" 
              onClick={(event: any) => { this.deleteHotlistPick(event, index); }} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index.toString()}>
            <Card.Body>
              <p><b>Date:</b> {date}</p>
              <p><b>Symbol:</b> {symbol}</p>
              <Setup 
                index={index} 
                hotlistPick={hotlistPick} 
                loadHotlistPicks={this.loadHotlistPicks}
                showModal={this.showModal}
              />
              <img src={hotlistPick.chartImageURL} alt={symbol} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });

    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <div className="text-center mb-2">
            <h4 className="d-inline-block mr-2">Hotlist for:</h4>
            <DayPickerInput
              dayPickerProps={
                {
                  disabledDays: [ 
                    { daysOfWeek: [0, 6] }
                  ],
                  showOutsideDays: true,
                  selectedDays: this.state.date
                }
              }
              formatDate={date => format(date, "MMM do")}
              placeholder={format(this.state.date, "MMM do")}
              onDayChange={this.setDate}
            />
          </div>
          <form id="hotlistPickForm" className="mb-3">
            <div className="form-group">
              <div className="d-flex flex-wrap">
                <div className="d-flex flex-grow-1 order-1">
                  <input id="exchange" name="exchange" type="text" className="form-control text-upper" placeholder="EXCHANGE" 
                    onChange={this.handleInputChange} pattern="^[a-zA-Z]+$" maxLength={10} required />
                  <span className="h4 mb-0 px-1">:</span>
                  <input id="stock" name="stock" type="text" className="form-control text-upper" placeholder="STOCK" 
                    onChange={this.handleInputChange} pattern="^[a-zA-Z]+$" maxLength={10} required />
                </div>
                <div className="flex-grow-1 flex-sm-grow-0 order-3 order-sm-2">
                  <button className="btn btn-learn2stock w-100" type="button" onClick={this.testSymbol} 
                    disabled={!this.state.exchangeValidation.valid || !this.state.stockValidation.valid}>
                    Test Symbol
                  </button>
                </div>
                <div className="w-100 order-2 order-sm-3 mb-2 mb-sm-0">
                  <Alert className="mb-0"
                    show={this.state.exchangeValidation.dirty && !this.state.exchangeValidation.valid}
                    variant="danger">
                    {this.state.exchangeValidation.validationMessage}
                  </Alert>
                  <Alert className="mb-0"
                    show={this.state.stockValidation.dirty && !this.state.stockValidation.valid}
                    variant="danger">
                    {this.state.stockValidation.validationMessage}
                  </Alert>
                </div>
              </div>
            </div>
            <div id="chartWidgetContainer" className="overflow-x-auto"></div>
            <div className="form-group">
              <textarea id="setup" name="setup" className="form-control" placeholder="Setup" rows={4} 
                onChange={this.handleInputChange} required />
              <Alert show={this.state.setupValidation.dirty && !this.state.setupValidation.valid} 
                variant="danger">
                {this.state.setupValidation.validationMessage}
              </Alert>
            </div>
            <div className="form-group">
              <label htmlFor="chartImage" className="font-weight-bold">Chart Image</label>
              <input id="chartImage" name="chartImage" type="file" className="form-control h-100" 
                accept="image/png, image/jpeg, image/gif" 
                onChange={this.handleInputChange} required />
              <Alert show={this.state.chartImageValidation.dirty && !this.state.chartImageValidation.valid} 
                variant="danger">
                {this.state.chartImageValidation.validationMessage}
              </Alert>
            </div>
            <div className="form-group">
              <button className="btn btn-learn2stock w-100" type="button" 
                onClick={this.uploadHotlistPick} disabled={!this.canUpload()}>
                Upload Hotlist Pick
              </button>
            </div>
          </form>          
          {
            hotlistPicks.length > 0 &&
            <>
              <div className="h4 text-center mb-0">Review Hotlist Picks</div>
              <Accordion>
                {hotlistPicks}
              </Accordion>
            </>
          }
          {
            hotlistPicks.length === 0 &&
            <div className="h4 text-center mt-2 mb-0">
              There are no hotlist picks for the selected date.
            </div>
          }
        </div>
      </section>
    );
  };
}

export default UploadHotlist;