import { useEffect, useRef, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import firebase from './firebase/firebase';
import { UserContext } from './firebase/firebase';
import ErrorBoundary from './components/ErrorBoundary';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import ManageUsers from './components/ManageUsers';
import ViewMessages from './components/ViewMessages';
import UploadHotlist from './components/UploadHotlist';
import ViewHotlist from './components/ViewHotlist';
import NotFound from './components/NotFound';

import './css/theme.css';
import './css/App.css';

const App = () => {
  const [auth, setAuth] = useState({ initialized: false, user: firebase.auth().currentUser });
  const history = useHistory();
  const historyRef = useRef(history);
  
  useEffect(() => {
    historyRef.current.listen(() => window.scrollTo(0, 0));
    firebase.auth().onAuthStateChanged(firebaseUser => {
      setAuth({ initialized: true, user: firebaseUser });
    });
  }, [auth.user]);

  if(!auth.initialized) {
    return <></>;
  }
  
  return (
    <UserContext.Provider value={auth.user}>
      <ErrorBoundary>
        <Navigation />
        <Switch>
          <Route exact path='/' component={Home} />

          <Route path='/login'>
            {auth.user != null ? <Redirect to="/manage-users" /> : <Login />}
          </Route>
          <Route path='/forgot-password' component={ForgotPassword}/>
          
          <Route path='/manage-users'>
            {auth.user == null ? <Redirect to="/login" /> : <ManageUsers />}
          </Route>
          <Route path='/view-messages'>
            {auth.user == null ? <Redirect to="/login" /> : <ViewMessages />}
          </Route>
          <Route path='/upload-hotlist'>
            {auth.user == null ? <Redirect to="/login" /> : <UploadHotlist history={history} />}
          </Route>
          <Route path='/view-hotlist'>
            {auth.user == null ? <Redirect to="/login" /> : <ViewHotlist />}
          </Route>

          <Route path='*' component={NotFound}/>
        </Switch>
      </ErrorBoundary>
    </UserContext.Provider>
  );
};

export default App;