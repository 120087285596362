import React from 'react';
import format from 'date-fns/format';
import subBusinessDays from 'date-fns/subBusinessDays';
import isWeekend from 'date-fns/isWeekend';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../scripts/tv.js';
import firebase from '../firebase/firebase';
import { hotlistsCollection } from '../firebase/firebase';
import IHotlistPick from '../interfaces/IHotlistPick';

class ViewHotlist extends React.Component {
  initialDate = () => {
    let date = new Date();
    date.setHours(12, 0, 0, 0);
    return isWeekend(date) ? subBusinessDays(date, 1) : date;
  };
  
  state = {
    date: this.initialDate(),
    hotlistPicks: [],
    showHotlistError: false
  };
  
  chartWidgetOptions = {
    "autoSize": true,
    "symbol": "",
    "interval": "D",
    "timezone": "Etc/UTC",
    "theme": "light",
    "style": "1",
    "locale": "en",
    "enable_publishing": false,
    "allow_symbol_change": false,
    "container_id": "chartWidgetContainer",
    "watchlist": [] as string[]
  };

  loadHotlistPicks = (date: Date) => {
    this.setState({ hotlistPicks: [], showHotlistError: false });
    date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
    const timestamp = firebase.firestore.Timestamp.fromDate(date);
    hotlistsCollection.where("date", "==", timestamp).get().then((hotlistPicksSnapshot) => {
      let hotlistPicks: IHotlistPick[] = [];
      let symbols: string[] = [];
      hotlistPicksSnapshot.forEach((doc) => {
        let docData = doc.data();
        let docDataTimestamp = docData.date as firebase.firestore.Timestamp;
        docData.date = docDataTimestamp.toDate();
        symbols.push(`${docData.exchange}:${docData.stock}`);
        hotlistPicks.push(docData as IHotlistPick);
      });
      this.chartWidgetOptions.symbol = symbols.length > 0 ? symbols[0] : "";
      this.chartWidgetOptions.watchlist = symbols;
      this.setState({
        hotlistPicks: hotlistPicks,
        showHotlistError: hotlistPicks.length > 0 ? false : true
      });
    }).catch(() => {
      this.setState({ showHotlistError: true });
    });
  };

  setDate = (date: Date) => {
    this.setState({ date: date });
    this.loadHotlistPicks(date);
  };

  componentDidMount = () => {
    let dayPickerInput = document.getElementsByClassName("DayPickerInput")[0].getElementsByTagName("input")[0];
    dayPickerInput.readOnly = true;
    this.loadHotlistPicks(this.state.date);
  };

  componentDidUpdate = () => {
    if (this.state.hotlistPicks.length > 0) {
      new (window as any).TradingView.widget(this.chartWidgetOptions);
    }
  };

  render() {
    const hotlistPicks = this.state.hotlistPicks.map((hotlistPick: IHotlistPick) => {
      return (
        <div key={hotlistPick.stock} className="text-center mt-3">
          <div className="h2">{hotlistPick.stock}</div>
          <div className="h4">{hotlistPick.setup}</div>
          <img src={hotlistPick.chartImageURL} alt={hotlistPick.stock} />
        </div>
      );
    });
    const liveChart = (
      <>
        <h2 className="text-center mt-3">Live Chart</h2>
        <div id="chartWidgetContainer" className="overflow-x-auto"></div>
      </>
    );

    return (
      <section>
        <div className="container">
          <h4 className="text-center">To ensure that you are using the hotlist effectively, here are a few things to consider:</h4>
          <div className="mb-3">
            <h6>$ Add all picks to your daily watch list and review them on a daily timeframe.</h6>
            <h6>$ Picks are made solely based on technical analysis/price action.</h6>
            <h6>$ Each Hotlist pick has presented the setup, however it's up to YOU to do your due diligence and look into the fundamentals of the company.</h6>
            <h6>$ These presented setups are intended to be Swing Trades!</h6>
            <h6>$ Some setups are not fully developed, and may require more confirmation before entering a trade.</h6>
            <h6>$ Our picks are only suggestions, and we strongly recommend that you do not buy into ALL of the picks. Pick your favorites!</h6>
          </div>
          <div className="text-center">
            <h4 className="d-inline-block mr-2">Hotlist for:</h4>
            <DayPickerInput 
              dayPickerProps={
                {
                  disabledDays: [ 
                    { daysOfWeek: [0, 6] }
                  ],
                  showOutsideDays: true,
                  selectedDays: this.state.date
                }
              }
              formatDate={date => format(date, "MMM do")}
              placeholder={format(this.state.date, "MMM do")}
              onDayChange={this.setDate}
            />
          </div>
          {
            this.state.hotlistPicks.length > 0 &&
            <>
              {hotlistPicks}
              {liveChart}
            </>
          }
          {
            this.state.showHotlistError &&
            <div className="h4 text-center mt-3 mb-0">There is no hotlist available for the selected date.</div>
          }
        </div>
      </section>
    );
  };
}

export default ViewHotlist;