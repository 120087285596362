import React from 'react';
import Alert from 'react-bootstrap/Alert';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { hotlistsCollection } from '../firebase/firebase';

class Setup extends React.Component<any, any> {
  state = {
    editSetup: false,
    setup: this.props.hotlistPick.setup,
    setupValidation: {
      dirty: false,
      valid: false,
      validationMessage: ""
    }
  };

  handleSetupChange = (event: any) => {
    this.setState({
      setup: event.target.value,
      setupValidation: {
        dirty: true,
        valid: event.target.checkValidity(),
        validationMessage: event.target.validationMessage
      }
    });
  };

  editSetup = () => {
    this.setState({ editSetup: true });
  };
  
  updateSetup = () => {
    let hotlistPick = this.props.hotlistPick;
    hotlistPick.setup = this.state.setup;

    const date = new Date(Date.UTC(hotlistPick.date.getUTCFullYear(), hotlistPick.date.getUTCMonth(), hotlistPick.date.getUTCDate(), 0, 0, 0, 0));
    const formattedHotlistDate = format(addMinutes(date, date.getTimezoneOffset()), "yyyyMMdd");
    const hotlistPickDocName = `${formattedHotlistDate}-${hotlistPick.stock}`;
    hotlistsCollection.doc(hotlistPickDocName).set(hotlistPick).then(() => {
      this.setState({ editSetup: false });
      this.props.loadHotlistPicks(hotlistPick.date);
    }).catch(() => {
      this.props.showModal("The setup could not be updated.");
    });
  };

  componentDidMount = () => {
    let setup = document.getElementById(`setup${this.props.index}`) as HTMLTextAreaElement;
    setup.value = this.state.setup;
    this.setState({ setup: this.props.hotlistPick.setup });
  };

  render() {
    return (      
      <form>
        <div className="form-group">
          <textarea id={`setup${this.props.index}`} className="form-control p-1" rows={4} 
            onChange={this.handleSetupChange} required disabled={!this.state.editSetup} />
          <Alert show={this.state.setupValidation.dirty && !this.state.setupValidation.valid} variant="danger">
            {this.state.setupValidation.validationMessage}
          </Alert>
        </div>
        <div className="form-group">
          {
            !this.state.editSetup &&
            <button className="btn btn-learn2stock w-100" type="button" onClick={this.editSetup}>
              Edit Setup
            </button>
          }
          {
            this.state.editSetup &&
            <button className="btn btn-learn2stock w-100" type="button" 
              onClick={this.updateSetup} disabled={!this.state.setupValidation.valid}>
              Update Setup
            </button>
          }
        </div>
      </form>
    );
  };
}

export default Setup;