import React from 'react';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class ManageUsers extends React.Component {  
  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
    }
  };

  render() {
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          <h1 className="text-center">Manage Users</h1>
        </div>
      </section>
    );
  };
}

export default ManageUsers;