import React from 'react';
import format from 'date-fns/format';
import firebase, { messagesCollection } from '../firebase/firebase';
import { IModalOptions } from '../interfaces/IModalOptions';
import ModalWindow from './ModalWindow';

class ViewMessages extends React.Component {
  state = {
    messages: []
  };

  modalOptions: IModalOptions = {
    title: "ERROR",
    body: "The messages were not retrieved.",
    action: "Close",
    show: false,
    closeModal: () => {
      this.modalOptions.show = false;
      this.setState({ showModal: false });
    }
  };

  componentDidMount = () => {
    let messages: any[] = [];
    messagesCollection.get().then((querySnapshot) => {
      querySnapshot.forEach(doc => {
        messages.push(doc.data());
      });
      messages = messages.sort((a, b) => {
        if(a.createdAt > b.createdAt) return -1;
        if(a.createdAt < b.createdAt) return 1;
        return 0;
      });
      this.setState({ messages: messages });
    }).catch(() => {
      this.modalOptions.show = true;
      this.setState({ showModal: true });
    });
  };

  render() {
    const messages = this.state.messages.map((message: any, index) => {
      let messageDate = message.createdAt as firebase.firestore.Timestamp;
      return (
        <tr key={index}>
          <td className="py-2 pr-2">{message.name}</td>
          <td className="p-2">{message.emailAddress}</td>
          <td className="p-2">{message.message}</td>
          <td className="py-2 pl-2">{format(messageDate.toDate(), "EEE MMM do, yyyy @ h:mm bb")}</td>
        </tr>
      );
    });
    const messageTable = (
      <table>
        <thead>
          <tr>
            <th className="py-2 pr-2">Name</th>
            <th className="p-2">Email Address</th>
            <th className="p-2">Message</th>
            <th className="py-2 pl-2">Date</th>
          </tr>
        </thead>
        <tbody>          
          {messages}
        </tbody>
      </table>
    );
    return (
      <section>
        <ModalWindow modalOptions={this.modalOptions}></ModalWindow>
        <div className="container">
          {messageTable}
        </div>
      </section>
    );
  };
}

export default ViewMessages;