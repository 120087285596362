import { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import firebase, { UserContext } from '../firebase/firebase';
import banner from '../img/l2s-banner.png';

const Navigation = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    firebase.auth().signOut().then(() => {
      history.push('/');
    });
  };
  
  return (
    <Navbar bg="light" expand="sm">
      <NavLink to='/' className="navbar-brand p-0">
        <img src={banner} alt="Learn2Stock Banner" height="40" />
      </NavLink>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {
          user == null &&
          <Nav className="ml-auto">
            <NavLink to='/login' className="nav-link">Log In</NavLink>
          </Nav>
        }
        {
          user != null &&
          <>
            <Nav className="mr-auto">
              <NavLink to='/manage-users' className="nav-link">Manage Users</NavLink>
              <NavLink to='/view-messages' className="nav-link">View Messages</NavLink>
              <NavLink to='/upload-hotlist' className="nav-link">Upload Hotlist Pick</NavLink>
              <NavLink to='/view-hotlist' className="nav-link">View Hotlist</NavLink>
            </Nav>
            <button type="button" className="btn-nav-link" onClick={logout}>Log Out</button>
          </>
        }
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
