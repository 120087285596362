import React from 'react';
import preval from 'preval.macro';
import format from 'date-fns/format';

class Home extends React.Component {
  buildDate = new Date(preval`module.exports = new Date();`);

  render() {
    return (
      <section>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-7 col-md-9">
              <div className="card py-3 shadow-lg">
                <div className="card-body">
                  <p className="display-3 mb-3 text-center">Learn2Stock Admin Panel</p>
                  <p className="lead mb-0"><b>Release Date: </b>{format(this.buildDate, "eeee MMM do, yyyy p")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default Home;